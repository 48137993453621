import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { LANGUAGES } from '@data/locale.data';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode, LanguageOptions } from './locale.types';
import { DateAdapter } from '@angular/material/core';
import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private languages = LANGUAGES;

  get currentLocale(): string {
    return this.translateService.currentLang;
  }

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>
  ) {}

  public async init(lang: LanguageCode) {
    this.translateService.addLangs(Object.keys(this.languages));
    this.applyLanguage(lang);
  }

  public getWord(word: string, params?: Record<string, unknown>): string {
    return this.translateService.instant(word, params);
  }

  public getWordAsync(word: string, params?: Record<string, unknown>) {
    return this.translateService.get(word, params);
  }

  public applyLanguage(lang: LanguageCode) {
    this.translateService.use(lang);
    if (lang === 'ru-RU') setDefaultOptions({ locale: ru });
    const options = this.options(lang);
    if (options?.secondaryLang) this.translateService.setDefaultLang(options.secondaryLang);

    if (options.locale) registerLocaleData(options.locale);
    this.dateAdapter.setLocale(lang);
  }

  public options(code: LanguageCode): LanguageOptions {
    return (this.languages[code] as LanguageOptions) || null;
  }
}
